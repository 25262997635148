/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Block } from 'bb/common/components';
import { PaddedBox, type PaddedBoxProps } from 'bb/common/components/PaddedBox';
import { Header, LimitaryText, Text } from 'bb/i18n';
import { Flex, Gap } from 'bb/ui';
import {
    type HeadingProp,
    makeTranslationProps
} from '../BookListCarouselSection';
import css from './headerSection.module.scss';

export type HeaderSectionProps = {
    children: React.ReactNode;
    title?: HeadingProp;
    heading: HeadingProp;
    subHeading?: HeadingProp;
    cta?: React.ReactNode;
} & PaddedBoxProps<'section'>;

const HeaderSection = ({
    children,
    title,
    heading,
    subHeading,
    bgColor = 'primary-white',
    cta,
    className,
    ...restProps
}: HeaderSectionProps) => (
    <PaddedBox
        as="section"
        className={className}
        bgColor={bgColor}
        {...restProps}
    >
        <Gap spacing={5}>
            <Block>
                <Flex
                    className={css.root}
                    direction="column"
                    alignItems="center"
                >
                    {/* More or less copied from start page */}
                    {title && (
                        <Text
                            as="span"
                            bold
                            {...makeTranslationProps(title)}
                            data-optimizely="headerSection-title"
                        />
                    )}
                    <Header
                        as="h2"
                        center
                        size="medium"
                        {...makeTranslationProps(heading)}
                        data-optimizely="headerSection-header"
                    />
                    <LimitaryText
                        marginBottom="none"
                        as="span"
                        center
                        {...makeTranslationProps(subHeading)}
                        data-optimizely="headerSection-subHeading"
                    />

                    {cta}
                </Flex>
            </Block>

            <Flex direction="column" alignItems="center">
                {children}
            </Flex>
        </Gap>
    </PaddedBox>
);

export default HeaderSection;
